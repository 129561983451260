.heatmap {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.heatmap p {
  margin: 0;
}

.heatmap__ar-wrapper {
  padding-bottom: 100%;
  position: relative;
}

.heatmap__coordinates {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;

  color: var(--ion-color-medium-shade);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.heatmap__x-axis {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: 24px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}

.heatmap__y-axis {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 24px;
}

.heatmap__y-axis-item {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.heatmap__inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.heatmap__heatmap {
  position: absolute;
  left: 24px;
  top: 24px;
  right: 0;
  bottom: 0;
}

.heatmap__bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 6px;
  overflow: hidden;
}

.heatmap__svg {
  width: 100%;
  height: 100%;
  fill: var(--ion-text-color);
  opacity: 0.1;
}

.heatmap__color-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 6px;
  overflow: hidden;
}

.heatmap__color-tile {
  border: 0 solid var(--ion-color-success-contrast);
  background-color: rgba(var(--ion-color-success-rgb), 0.4);
}

.heatmap__color-tile.is-highlighted {
  background-color: rgba(var(--ion-color-danger-rgb), 0.4);
}

.heatmap__color-tile--border-bottom {
  border-bottom-width: 1px;
}

.heatmap__color-tile--border-right {
  border-right-width: 1px;
}

.heatmap__action-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.heatmap__action-overlay-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.heatmap__action-tile {
  background: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
}

.heatmap__action-tile span {
  display: none;
}

/* Submit (outside heatmap) */
.heatmap-submit {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
}
