.ndef-reader__content {
  min-height: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ndef-reader__tag {
  width: 100%;
  max-width: 120px;
  margin: 0 auto;
  opacity: 0.1;
}

.ndef-reader__tag.animated {
  animation-name: tagPulse;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

@keyframes tagPulse {
  0% {
    opacity: 0.1;
    transform: scale(1);
  }

  100% {
    opacity: 0.15;
    transform: scale(1.1);
  }
}
